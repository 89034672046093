import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";

import { auth, db } from "../firebase";
import styles from "./ScormPage.module.css";
import {
  BORDER_COLOR,
  PADDING,
  PRIMARY_BACKGROUND_COLOR,
  PRIMARY_COLOR,
  FONT_COLOR,
} from "../common/variables";

import { uidSelector } from "../features/userSlice";
import ScormImport from "../features/scormImport/ScormImport";
import {
  APP_BORDER_RADIUS,
  PRIMARY_BACKGROUND_COLOR_ACTIVE,
} from "../common/variables";

import { ReactComponent as Logo } from "../common/_assets/svg/logo.svg";
import ScormFilesList from "../common/scorm/ScormFilesList";
import AppButton from "../common/components/AppButton";
import url from "../app/host";
import Toast from "../features/toast/Toast";
import Checkbox from "../common/components/Checkbox";
import {
  selectTheme,
  selectOption,
  capitalizeFirstLetter,
  selectDropdownIndicatorStyle,
  selectMenuListStyle,
  selectMenuStyle,
  getAvailableLanguages,
  adjustLanguageName,
} from "../functions";
import CloseIcon from "../common/components/CloseIcon";

const ITEM_TYPE = "captions";
const ITEM_FOLDERS = "captionFolders";
const LIST_ROW_HEIGHT = 30;

function ScormPage() {
  const [toastRef, generateToastMessage] = useToast();
  const uid = useSelector(uidSelector);
  const [filesArray, setFilesArray] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useCurrentTab(setIsLoading);
  const [isLanguagesModalOpen, setIsLanguagesModalOpen] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState({});

  function showContiniuePopup(callback) {
    if (
      window.confirm(
        "If you leave this tab now, your ongoing process will be cancelled. Do you want to continiue?"
      )
    ) {
      callback();
    }
  }

  // files listener
  useEffect(() => {
    if (uid) {
      // set folders
      const filesRef = db.ref(`${ITEM_TYPE}/${uid}`);
      let filesListener = null;

      const foldersRef = db.ref(`${ITEM_FOLDERS}/${uid}`);
      const foldersListener = foldersRef.on("value", (snapshot) => {
        const foldersList = snapshot.val()?.root || {};

        let foldersObj = {};
        Object.keys(foldersList).map((key) => {
          const dbFolderPath = `root/${key}`;
          if (foldersList[key]?.isScorm) {
            foldersObj[dbFolderPath] = {
              ...foldersList[key],
              folderId: key,
              totalFileCount: 0,
              doneFileCount: 0,
              availableLanguages: {},
            };
          }

          return null;
        });

        // add scorm status
        let foldersObjClone = foldersObj || {};
        filesListener = filesRef.on("value", (snapshot) => {
          foldersObjClone = _.cloneDeep(foldersObj);
          const filesList = snapshot.val() || {};
          Object.keys(filesList).map((key) => {
            const tmpFolderName = filesList[key]?.folderName;
            if (foldersObj[tmpFolderName]) {
              // update file count
              foldersObjClone[tmpFolderName].totalFileCount += 1;
              if (filesList[key]?.status === "done") {
                foldersObjClone[tmpFolderName].doneFileCount += 1;
              }

              // update available languages list
              if (filesList?.[key]?.languages) {
                for (
                  let i = 0;
                  i < Object.keys(filesList?.[key]?.languages).length;
                  i++
                ) {
                  if (
                    Object.keys(filesList?.[key]?.languages)[i] &&
                    !foldersObjClone[tmpFolderName].availableLanguages[
                      Object.keys(filesList?.[key]?.languages)[i]
                    ]
                  ) {
                    foldersObjClone[tmpFolderName].availableLanguages[
                      Object.keys(filesList?.[key]?.languages)[i]
                    ] = true;
                  }
                }
              }
            }
            return null;
          });

          const newFilesArray = Object.keys(foldersObjClone)
            .map((folderDbPathKey) => {
              return foldersObjClone[folderDbPathKey];
            })
            .filter((fileObj) => fileObj)
            .sort((a, b) => {
              const aDate = a.created;
              const bDate = b.created;
              return moment.utc(bDate).diff(moment.utc(aDate));
            });

          setFilesArray(() => newFilesArray);
        });
      });

      return () => {
        foldersRef.off("value", foldersListener);
        filesRef.off("value", filesListener);
      };
    }
  }, [uid]);

  return (
    <div
      className="pageContainer"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        currentTab={currentTab}
        generateToastMessage={generateToastMessage}
      />

      <div className={styles.availableAreaContainer}>
        <div className={styles.contentContainer}>
          <TabsSelection
            currentTab={currentTab}
            isLoading={isLoading}
            setCurrentTab={setCurrentTab}
            showContiniuePopup={showContiniuePopup}
          />
          <CurrentTab
            uid={uid}
            currentTab={currentTab}
            filesArray={filesArray}
            selectedFileId={selectedFileId}
            setCurrentTab={setCurrentTab}
            setSelectedFileId={setSelectedFileId}
            generateToastMessage={generateToastMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isLanguagesModalOpen={isLanguagesModalOpen}
            setIsLanguagesModalOpen={setIsLanguagesModalOpen}
            selectedLanguages={selectedLanguages}
          />
        </div>
      </div>

      {/* toast */}
      <Toast ref={toastRef} />

      {/* Languages Dropdown */}
      <UploadLanguageModal
        isLanguagesModalOpen={isLanguagesModalOpen}
        setIsLanguagesModalOpen={setIsLanguagesModalOpen}
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
      />
    </div>
  );
}

// START - COMPONENTS
function UploadLanguageModal(props) {
  const [selectedUploadLangugages, setSelectedUploadLangugages] = useState({
    ...props.selectedLanguages,
  });

  if (props.isLanguagesModalOpen) {
    const dropdownLanguages = getAvailableLanguages();

    function addSelectedUploadLangugages(option) {
      if (
        option?.value &&
        option?.value !== "" &&
        !selectedUploadLangugages[option.value]
      ) {
        let newSelectedUploadLanguages = _.cloneDeep(selectedUploadLangugages);
        newSelectedUploadLanguages[option.value] = option.label;
        setSelectedUploadLangugages(newSelectedUploadLanguages);
      }
    }

    function removeSelectedUploadLangugages(languageKey) {
      if (languageKey && selectedUploadLangugages[languageKey]) {
        let newSelectedUploadLanguages = _.cloneDeep(selectedUploadLangugages);
        delete newSelectedUploadLanguages[languageKey];
        setSelectedUploadLangugages(newSelectedUploadLanguages);
      }
    }

    function getSelectedUploadLangugagesList() {
      return Object.keys(selectedUploadLangugages).map((languageKey) => {
        return (
          <div
            className={styles.selectedUploadLangugagesItem}
            key={`uploadLanguage_${languageKey}`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: 30,
              padding: `0 ${PADDING} 0 ${PADDING}`,
              borderBottom: `1px solid ${BORDER_COLOR}`,
            }}
          >
            {selectedUploadLangugages[languageKey]}
            <div
              onClick={() => {
                removeSelectedUploadLangugages(languageKey);
              }}
              style={{
                cursor: "pointer",
                marginLeft: PADDING,
                padding: `0 7px`,
              }}
            >
              <CloseIcon color={FONT_COLOR} size={8} />
            </div>
          </div>
        );
      });
    }

    function closeModal(isSave) {
      if (isSave) {
        props.setSelectedLanguages(selectedUploadLangugages);
      }

      props.setIsLanguagesModalOpen(false);
    }

    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 20,
            boxShadow: "0 2px 6px 0 rgba(0,0,0,0.2)",
            minHeight: 350,
            minWidth: 350,
            backgroundColor: "white",
            padding: 21,
          }}
        >
          {/* Header */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: PADDING,
              width: "100%",
            }}
          >
            <div className={styles.titleText}>Translate Languages</div>

            <div
              onClick={() => {
                closeModal();
              }}
              style={{
                cursor: "pointer",
                marginLeft: PADDING,
              }}
            >
              <CloseIcon color={FONT_COLOR} />
            </div>
          </div>

          {/* Language selection */}

          <Select
            menuPlacement="bottom"
            isSearchable={true}
            styles={dropdownStyle}
            theme={selectTheme}
            value={{
              value: "",
              label: "Select",
            }}
            onChange={addSelectedUploadLangugages}
            options={dropdownLanguages}
          />

          {/* Langugaes List */}
          <div
            style={{
              flex: 1,
              backgroundColor: PRIMARY_BACKGROUND_COLOR_ACTIVE,
              borderRadius: APP_BORDER_RADIUS,
              width: "100%",
              overflow: "auto",
              maxHeight: 250,
              margin: `${PADDING} 0 ${PADDING} 0`,
            }}
          >
            {getSelectedUploadLangugagesList()}
          </div>

          <div
            className="appButton"
            onClick={() => {
              closeModal(true);
            }}
          >
            Save
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function CurrentTab(props) {
  if (props.currentTab === "import") {
    return (
      <ScormImport
        filesArray={props.filesArray}
        setCurrentTab={props.setCurrentTab}
        generateToastMessage={props.generateToastMessage}
        isLoading={props.isLoading}
        setIsLoading={props.setIsLoading}
        isLanguagesModalOpen={props.isLanguagesModalOpen}
        setIsLanguagesModalOpen={props.setIsLanguagesModalOpen}
        selectedLanguages={props.selectedLanguages}
      />
    );
  } else if (props.currentTab === "export") {
    return (
      <ScormExport
        uid={props.uid}
        filesArray={props.filesArray}
        selectedFileId={props.selectedFileId}
        setSelectedFileId={props.setSelectedFileId}
        generateToastMessage={props.generateToastMessage}
        isLoading={props.isLoading}
        setIsLoading={props.setIsLoading}
      />
    );
  }
}

function ScormExport(props) {
  const [exportUrl, setExportUrl] = useState(null);
  const [isExportWithCaptions, setIsExportWithCaptions] = useState(true);
  const [exportLanguage, setExportLanguage] = useState({
    value: null,
    label: "Select...",
  });

  const buttonText = exportUrl
    ? "Download"
    : props.isLoading
    ? "Loading..."
    : "Export";

  function toggleIsExportWithCaptions() {
    setIsExportWithCaptions(!isExportWithCaptions);
  }

  function getLanguageOptions() {
    let availableLanguages = [];
    if (props?.selectedFileId && props?.filesArray?.length > 0) {
      for (let i = 0; i < props?.filesArray?.length; i++) {
        if (
          props?.filesArray[i]?.folderId === props?.selectedFileId &&
          props?.filesArray[i]?.availableLanguages
        ) {
          for (
            let j = 0;
            j < Object.keys(props?.filesArray[i]?.availableLanguages).length;
            j++
          ) {
            if (Object.keys(props?.filesArray[i]?.availableLanguages)[j]) {
              availableLanguages.push({
                value: Object.keys(props?.filesArray[i]?.availableLanguages)[j],
                label: capitalizeFirstLetter(
                  adjustLanguageName(
                    Object.keys(props?.filesArray[i]?.availableLanguages)[j]
                  )
                ),
              });
            }
          }
        }
      }
    }

    return availableLanguages;
  }

  function handleSubmit() {
    if (!props.isLoading) {
      if (props.selectedFileId && exportLanguage?.value) {
        props.setIsLoading(true);
        props.generateToastMessage(
          "Generating your file. This process might take few minutes."
        );

        const configObj = {
          uid: props.uid,
          folderId: props.selectedFileId,
          itemType: ITEM_TYPE,
          exportLanguage: exportLanguage.value,
          displayCaptionsByDefault: isExportWithCaptions,
        };

        fetch(`${url}/api/exportScormFile`, {
          method: "POST",
          body: JSON.stringify(configObj),
          headers: { "Content-type": "text/plain" },
        })
          .then((res) => res.text())
          .then((res) => {
            const parsedRes = JSON.parse(res);
            if (parsedRes.url) {
              setExportUrl(parsedRes.url);
              props.generateToastMessage("Your file is ready to download.");
            } else {
              props.generateToastMessage(
                "There was an error getting the export link. Please contact support."
              );
            }
            props.setIsLoading(false);
          })
          .catch((err) => {
            props.generateToastMessage(
              "There was an error while exporting the SCORM file. Please contact support."
            );
            props.setIsLoading(false);
          });
      } else {
        if (!props.selectedFileId) {
          props.generateToastMessage("Please select a file");
        } else if (!exportLanguage?.value) {
          props.generateToastMessage("Please select the export language.");
        }
      }
    }
  }

  function resetExportUrl() {
    setExportUrl(null);
  }

  const exportLanguageOptions = getLanguageOptions();

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <ScormFilesListHeader />
      <div style={{ flex: 1 }}>
        <ScormFilesList
          filesArray={props.filesArray}
          rowHeight={LIST_ROW_HEIGHT}
          selectedFileId={props.selectedFileId}
          setSelectedFileId={props.setSelectedFileId}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: PADDING,
          borderTop: `1px solid ${BORDER_COLOR}`,
          width: "100%",
        }}
      >
        <div
          className={styles.boxButtonsRow}
          style={{ flex: 1, marginBottom: 14 }}
        >
          <Checkbox
            text="Show captions by default"
            checked={isExportWithCaptions}
            onClick={toggleIsExportWithCaptions}
          />

          <div style={{ paddingRight: 14 }} />

          <Select
            menuPlacement="top"
            isSearchable={true}
            styles={dropdownStyle}
            theme={selectTheme}
            value={exportLanguage}
            onChange={setExportLanguage}
            options={exportLanguageOptions}
          />
        </div>

        <div className={styles.boxButtonsRow}>
          <ExportButton
            text={buttonText}
            exportUrl={exportUrl}
            isLoading={props.isLoading}
            onClick={handleSubmit}
            setExportUrl={setExportUrl}
            generateToastMessage={props.generateToastMessage}
          />

          <ClearDownloadButton exportUrl={exportUrl} onClick={resetExportUrl} />
        </div>
      </div>
    </div>
  );
}

function ExportButton(props) {
  if (props.exportUrl) {
    return (
      <a
        className="appButton"
        href={props.exportUrl}
        onClick={() => {
          props.generateToastMessage("Downloading your file.");
          props.setExportUrl(null);
        }}
      >
        {props.text}
      </a>
    );
  } else {
    return <AppButton text={props.text} onClick={props.onClick} />;
  }
}

function ClearDownloadButton(props) {
  if (props.exportUrl) {
    return (
      <div
        className="appButton"
        onClick={props.onClick}
        style={{
          padding: 7,
          borderRadius: 50,
          marginLeft: PADDING,
          height: 30,
          width: "auto",
        }}
      >
        <CloseIcon />
      </div>
    );
  } else {
    return null;
  }
}

function ScormFilesListHeader() {
  return (
    <div
      className={styles.listItem}
      style={{
        height: LIST_ROW_HEIGHT,
        backgroundColor: PRIMARY_BACKGROUND_COLOR,
        fontWeight: "bold",
        opacity: 0.8,
      }}
    >
      <div className={styles.listItemColumn} style={{ flex: 1 }}>
        Name
      </div>
      <div className={styles.listItemColumn}>Status</div>
      <div className={styles.listItemColumn}>Upload Date</div>
    </div>
  );
}

function TabsSelection(props) {
  const indicatorLeft =
    props.currentTab === "export" ? 0 : `${parseFloat(100 / 2)}%`;
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 40,
        borderTopRightRadius: APP_BORDER_RADIUS,
        borderTopLeftRadius: APP_BORDER_RADIUS,
        borderBottom: `1px solid ${BORDER_COLOR}`,
      }}
    >
      <div
        className={styles.tabButton}
        style={{ opacity: props.currentTab === "export" ? 1 : undefined }}
        onClick={() => {
          if (!props.isLoading) {
            props.setCurrentTab("export");
          } else {
            props.showContiniuePopup(() => {
              props.setCurrentTab("export");
            });
          }
        }}
      >
        EXPORT
      </div>
      <div
        className={styles.tabButton}
        style={{ opacity: props.currentTab === "import" ? 1 : undefined }}
        onClick={() => {
          if (!props.isLoading) {
            props.setCurrentTab("import");
          } else {
            props.showContiniuePopup(() => {
              props.setCurrentTab("import");
            });
          }
        }}
      >
        UPLOAD
      </div>

      <div
        style={{
          position: "absolute",
          left: indicatorLeft,
          width: `${parseFloat(100 / 2)}%`,
          borderBottom: `2px solid ${PRIMARY_COLOR}`,
          transition: "all 0.2s",
          marginTop: -1.5,
          bottom: 0,
        }}
      />
    </div>
  );
}

function Header(props) {
  const headerText =
    props.currentTab === "import" ? "Upload SCORM" : "Export SCORM";

  function handleSignOut() {
    auth.signOut().catch(() => {
      props.generateToastMessage(
        "There was an error signong out. Please try again."
      );
    });
  }

  return (
    <div className={styles.header}>
      {/* logo */}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.open("https://app.maestrasuite.com/", "_blank");
        }}
      >
        <Logo style={{ width: 114, height: "auto" }} />
      </div>

      {/* title */}
      <div className={styles.titleText}>{headerText}</div>

      {/* sign out */}
      <div style={{ width: 114 }}>
        <div
          className="appButton"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: `4px 8px 4px 8px`,
            fontSize: 14,
            height: 30,
            width: 80,
            marginLeft: "auto",
          }}
          onClick={handleSignOut}
        >
          Sign out
        </div>
      </div>
    </div>
  );
}
// END - COMPONENTS

// START - FUNCTIONS
function useCurrentTab(setIsLoading) {
  const [currentTab, tmpSetCurrentTab] = useState("export");
  function setCurrentTab(val) {
    tmpSetCurrentTab(val);
    setIsLoading(false);
  }

  return [currentTab, setCurrentTab];
}

function useToast() {
  const toastRef = useRef(null);
  const generateToastMessage = toastRef?.current?.notify;

  return [toastRef, generateToastMessage];
}

// END - FUNCTIONS

const dropdownStyle = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#EFF2F7",
    color: "#4B78AF",
    border: "2px solid #EFF2F7",
    width: 200,
    minHeight: "unset",
    fontSize: 13,
    fontWeight: 600,
    textOverflow: "clip",
    cursor: "pointer",
    transition: "all 0.15s",

    "&:hover": {
      border: "2px solid #D6DDEA",
    },

    "&:active": {
      border: "2px solid #D6DDEA",
    },

    ":before": {
      content: '"Language: "',
      color: "#576476",
      paddingLeft: 7,
    },
  }),
  option: selectOption,
  menu: selectMenuStyle,
  menuList: selectMenuListStyle,
  dropdownIndicator: selectDropdownIndicatorStyle,
};

export default ScormPage;
