import React from "react";

function AppButton(props) {
  return (
    <div className="appButton" onClick={props.onClick}>
      {props.text}
    </div>
  );
}

export default AppButton;
