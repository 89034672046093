import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uid: null,
  email: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      if (action.payload) {
        state.uid = action.payload.uid;
        state.email = action.payload.email;
      } else {
        state.uid = initialState.uid;
        state.email = initialState.email;
      }
    },
  },
});

export const { setUser } = userSlice.actions;

export const userSelector = (state) => state.user;

export const uidSelector = (state) => state.user?.uid;

export default userSlice.reducer;
