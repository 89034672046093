import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import Select from "react-select";

import styles from "../../pages/ScormPage.module.css";
import { uidSelector } from "../userSlice";
import { BORDER_COLOR, FONT_COLOR } from "../../common/variables";
import { PADDING } from "../../common/variables";
import url from "../../app/host";

import AppButton from "../../common/components/AppButton";
import { auth, storage, db } from "../../firebase";
import {
  selectTheme,
  selectOption,
  selectMenuStyle,
  selectMenuListStyle,
  selectDropdownIndicatorStyle,
  getAvailableLanguages,
} from "../../functions";

const ITEM_TYPE = "captions";

function ScormImport(props) {
  const uid = useSelector(uidSelector);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });

  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [currentScormFolderId, setCurrentScormFolderId] = useState(null);
  const [uploadingPercentage, setUploadingPercentage] = useState(null);
  const [originalLanguage, setOriginalLanguage] = useState({
    value: "english",
    label: "English",
  });

  const availableLanguages = getAvailableLanguages();
  const uploadText = props.isLoading ? "Loading..." : "Upload";
  const translatedLanguageCount = props?.selectedLanguages
    ? Object.keys(props?.selectedLanguages)?.length
    : 0;

  function handleDrop(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length === 1) {
      // this.generateDynamicMessage(`Uploading file...`);
      const scormFile = acceptedFiles[0];
      const scormFileName = scormFile.name;
      setFileName(scormFileName);
      setFile(scormFile);
      setCurrentScormFolderId(generateScormFolderId(uid));
    } else {
      props.generateToastMessage("You can only upload 1 SCORM file at a time.");
    }
  }

  function handleSubmit() {
    if (!props.isLoading) {
      props.setIsLoading(true);
      uploadScormFile(
        file,
        uid,
        currentScormFolderId,
        setUploadingPercentage,
        originalLanguage,
        props.selectedLanguages
      )
        .then((res) => {
          props.setIsLoading(false);
          props.setCurrentTab("export");
          setUploadingPercentage(null);
        })
        .catch((err) => {
          props.setIsLoading(false);
          props.generateToastMessage(err);
          setUploadingPercentage(null);
        });
    }
  }

  function toggleLanguagesModal() {
    props.setIsLanguagesModalOpen(!props.isLanguagesModalOpen);
  }

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        {...getRootProps()}
        className={styles.dropzoneContainer}
        style={{
          flex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          color: FONT_COLOR,
          width: "100%",
          borderBottom: `1px solid ${BORDER_COLOR}`,
        }}
      >
        <input {...getInputProps()} />
        <div
          style={{
            textAlign: "center",
            padding: `0 ${PADDING} 0 ${PADDING}`,
          }}
        >
          <DropzoneText
            fileName={fileName}
            currentScormFolderId={currentScormFolderId}
            isLoading={props.isLoading}
            filesArray={props.filesArray}
            uploadingPercentage={uploadingPercentage}
          />
        </div>
      </div>

      <div
        style={{
          padding: PADDING,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            paddingBottom: 14,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Select
            menuPlacement="top"
            isSearchable={true}
            styles={dropdownStyle}
            theme={selectTheme}
            value={originalLanguage}
            onChange={setOriginalLanguage}
            options={availableLanguages}
          />

          <div style={{ paddingRight: 14 }} />

          <div
            className={styles.primaryWhiteButton}
            onClick={toggleLanguagesModal}
            style={{
              // display: this.props.isFileNameHidden ? "none" : "flex",
              height: 34,
              boxSizing: "border-box",
              minWidth: 115,
              lineHeight: "12px",
              padding: 8,
              textAlign: "right",
              fontSize: 12,
            }}
          >
            Translate Languages ({translatedLanguageCount})
          </div>

          {/* <AppButton text={uploadText} onClick={handleSubmit} /> */}
        </div>
        <div>
          <AppButton text={uploadText} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
}

// START - COMPONENTS
function DropzoneText(props) {
  if (props.uploadingPercentage) {
    const uploadingPercentage =
      props.uploadingPercentage === 100
        ? "Processing files..."
        : `Uploading... (${props.uploadingPercentage}%)`;
    return <>{uploadingPercentage}</>;
  }

  if (props.filesArray && props.filesArray.length > 0) {
    for (let i = 0; i < props.filesArray.length; i++) {
      if (
        props.filesArray[i].folderId === props.currentScormFolderId &&
        props.filesArray[i].scormStatus
      ) {
        return <>{getStatusString(props.filesArray[i])}</>;
      }
    }
  }

  if (props.isLoading) {
    return (
      <>
        Generating files...
        <br />
        This might take few minutes
      </>
    );
  } else if (props.fileName) {
    return <>{props.fileName}</>;
  } else {
    return (
      <>
        To upload your SCORM file
        <br />
        please click here or drag/drop your file
      </>
    );
  }
}
// END - COMPONENTS

// START - FUNCTIONS
function getStatusString(scormFolderObj) {
  const totalFileCount = scormFolderObj?.totalFileCount || 0;
  const doneFileCount = scormFolderObj?.doneFileCount || 0;

  if (totalFileCount > 0) {
    if (totalFileCount === doneFileCount) {
      return "Done";
    } else {
      return `Transcribing ${doneFileCount}/${totalFileCount}`;
    }
  } else {
    // probably failed
    return "";
  }
}

function generateScormFolderId(uid) {
  let dbFoldersTable = "captionFolders";
  if (ITEM_TYPE === "voiceovers") {
    dbFoldersTable = "voiceoverFolders";
  }

  const foldersRef = db.ref(`${dbFoldersTable}/${uid}`);
  const folderId = foldersRef.push().key;

  return folderId;
}

function getUploadtargetLanguages(selectedLanguages) {
  let targetLanguages = {};
  if (
    typeof selectedLanguages === "object" &&
    Object.keys(selectedLanguages).length > 0
  ) {
    Object.keys(selectedLanguages).map((languageKey) => {
      targetLanguages[languageKey] = true;
    });
  }

  return targetLanguages;
}

function uploadScormFile(
  file,
  uid,
  currentScormFolderId,
  setUploadingPercentage,
  originalLanguage,
  selectedLanguages
) {
  return new Promise(async (resolve, reject) => {
    if (uid) {
      if (file && originalLanguage?.value) {
        const fileName = file.name;
        if (fileName?.endsWith(".zip")) {
          var storageRef = storage.ref(
            `${uid}/uploadedScorm/${currentScormFolderId}.zip`
          );
          storageRef.put(file).on(
            "state_changed",
            (snapshot) => {
              let progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              progress = progress ? Math.round(progress) : 0;
              setUploadingPercentage(progress);
            },
            (error) => {
              console.log(error);
              reject(
                "Upload failed. If this issue persists, please contact support."
              );
            },
            async () => {
              const targetLanguages = getUploadtargetLanguages(
                selectedLanguages
              );
              const idToken = await auth.currentUser.getIdToken(true);
              let config = {
                idToken,
                scormFolderId: currentScormFolderId,
                fileName: file.name,
                originalLanguage: originalLanguage?.value,
                targetLanguages,
              };
              fetch(`${url}/api/uploadScormFile`, {
                method: "POST",
                body: JSON.stringify(config),
              })
                .then((res) => {
                  resolve(res);
                })
                .catch((err) => {
                  reject(
                    "Upload failed. If this issue persists, please contact support."
                  );
                });
            }
          );
        } else {
          reject("SCORM files should be .zip files.");
        }
      } else {
        reject("Please select a file.");
      }
    } else {
      reject("Not authenticated. Please contact support.");
    }
  });
}
// END - FUNCTIONS

const dropdownStyle = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#EFF2F7",
    color: "#4B78AF",
    border: "2px solid #EFF2F7",
    width: 200,
    minHeight: "unset",
    fontSize: 13,
    fontWeight: 600,
    textOverflow: "clip",
    cursor: "pointer",
    transition: "all 0.15s",

    "&:hover": {
      border: "2px solid #D6DDEA",
    },

    "&:active": {
      border: "2px solid #D6DDEA",
    },

    ":before": {
      content: '"Language: "',
      color: "#576476",
      paddingLeft: 7,
    },
  }),
  option: selectOption,
  menu: selectMenuStyle,
  menuList: selectMenuListStyle,
  dropdownIndicator: selectDropdownIndicatorStyle,
};

export default ScormImport;
