import React from "react";

export default function CloseIcon(props) {
  return (
    <svg
      height={props.size || 14}
      width={props.size || 14}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{
          stroke: props.color || "white",
          strokeWidth: 2,
          fill: "none",
          fillRule: "evenodd",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        transform="translate(1 1)"
      >
        <path d="m12 0-12 12" />
        <path d="m0 0 12 12" />
      </g>
    </svg>
  );
}
