// @flow
import React, { Component } from "react";
import { ReactComponent as CheckIcon } from "../_assets/svg/checkIcon.svg";

class Checkbox extends Component {
  getCheckbox = () => {
    return (
      <div
        className="editorPlayerBoxCheckbox"
        style={{
          position: "relative",
          boxSizing: "border-box",
          height: this.props.size,
          width: this.props.size,
          backgroundColor: this.props.backgroundColor,
          borderRadius: this.props.borderRadius,
          border: this.props.border,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          alt="Check Icon"
          style={{
            position: "absolute",
            display: this.props.checked ? "initial" : "none",
          }}
        >
          <CheckIcon />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{ display: "inline-block" }}>
        <div style={styles.containter} onClick={this.props.onClick}>
          {this.getCheckbox()}
          <div style={{ paddingLeft: 7, whiteSpace: "nowrap" }}>
            {this.props.text}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  containter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    flexGrow: 0,
    fontWeight: 700,
  },
};

Checkbox.defaultProps = {
  checked: false,
  fontSize: 14,
  size: 15,
  tickRatio: "66%",
  text: "",
  tickColor: "#46505D",
  borderRadius: 3,
  border: "1px solid #C6CBD4",
  hoverColor: "#e1e4e8",
};

export default Checkbox;
