import _ from "lodash";
import captionTargetLanguages from "./app/captionTargetLanguages.json";

export function capitalizeFirstLetter(text) {
  if (typeof text === "string" && text.length > 0) {
    const capitalized = text.charAt(0).toUpperCase() + text.slice(1);
    return capitalized;
  }
}

// START - DROPDOWN STYLE
// START - DROPDOWN STYLES
export const selectOption = (provided, state) => ({
  backgroundColor: "white",
  // borderTop: '1px solid #C6CBD4',
  color: state.isSelected ? "#718096" : "#273444",
  borderRadius: "10px",
  padding: "10px 10px",
  fontSize: 14,
  fontWeight: 600,
  cursor: "pointer",

  "&:hover": {
    color: "#008AFF",
  },

  "&:active": {
    color: "#008AFF",
  },
});

export const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#d9d9d9",
    neutral80: "#152C5B",
    neutral20: "transparent",
  },
});

export const selectMenuStyle = (provided, state) => ({
  ...provided,
  border: "1px solid #eff2f7",
  borderRadius: "10px",
  padding: 0,
  overflow: "visible",
  boxShadow: "0 1px 4px 0 rgba(0,0,0,0.1)",
});

export const selectMenuListStyle = (provided, state) => ({
  ...provided,
  padding: 0,
  borderRadius: "10px",
  maxHeight: 200,
});

export const selectDropdownIndicatorStyle = (provided, state) => ({
  ...provided,
  color: "#C6CBD4",
  paddingLeft: 5,
  height: 23,
  paddingTop: 2,
});
// END - DROPDOWN STYLE

export function adjustLanguageName(key) {
  if (key === "chineset") {
    return "Chinese (Traditional)";
  }
  if (key === "chinese") {
    return "Chinese (Simplified)";
  }
  return key;
}

export function getAvailableLanguages(existingLanguageArray = []) {
  let languagesArray = [];

  _.forEach(Object.keys(captionTargetLanguages), (key) => {
    if (!existingLanguageArray.includes(key)) {
      languagesArray.push({
        label: capitalizeFirstLetter(adjustLanguageName(key)),
        value: key,
      });
    }
  });

  return languagesArray;
}
