import React from "react";

import styles from "./ScormPage.module.css";
import { ReactComponent as Logo } from "../common/_assets/svg/logo.svg";

function LoadingPage(props) {
  return (
    <div>
      <Header />
    </div>
  );
}

function Header(props) {
  const headerText =
    props.currentTab === "import" ? "Upload SCORM" : "Export SCORM";

  return (
    <div className={styles.header}>
      {/* logo */}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.open("https://app.maestrasuite.com/", "_blank");
        }}
      >
        <Logo style={{ width: 114, height: "auto" }} />
      </div>

      {/* title */}
      <div className={styles.titleText}>{headerText}</div>

      {/* sign out */}
      <div style={{ width: 114 }}></div>
    </div>
  );
}

export default LoadingPage;
