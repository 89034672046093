// @flow
import React, { Component } from "react";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

class Toast extends Component {
  notify = (message) => {
    toast.info(message, { className: "toast" });
  };

  render() {
    return (
      <ToastContainer
        autoClose={4000}
        hideProgressBar
        draggable={false}
        position="bottom-right"
      />
    );
  }
}

export default Toast;
