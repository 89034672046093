import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import { HOME_ROUTE, AUTH_ROUTE, SCORM_ROUTE } from "./app/routes";

import { setUser, userSelector } from "./features/userSlice";
import { auth } from "./firebase";
import AuthPage from "./pages/AuthPage";
import LoadingPage from "./pages/LoadingPage";
import ScormPage from "./pages/ScormPage";

function Routes() {
  useUserListener();

  return (
    <div className="routesContainer">
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path={AUTH_ROUTE}>
          <AuthPage />
        </Route>
        <Route path={SCORM_ROUTE}>
          <ScormPage />
        </Route>
        <Route path={HOME_ROUTE}>
          <LoadingPage />
        </Route>
      </Switch>
    </div>
  );
}

export default Routes;

function useUserListener() {
  const [isInitialUserFetched, setIsInitialUserFetched] = useState(false);
  const user = useSelector(userSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  // user listener
  useEffect(() => {
    const unlisten = auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          setUser({
            uid: user.uid,
            email: user.email,
          })
        );
        setIsInitialUserFetched(true);
      } else {
        dispatch(setUser(null));
        setIsInitialUserFetched(true);
      }
    });

    return () => {
      unlisten();
    };
  }, [dispatch, setIsInitialUserFetched]);

  // did mount check / update path when user state changes
  useEffect(() => {
    if (isInitialUserFetched) {
      if (user?.uid) {
        history.push(SCORM_ROUTE);
      } else {
        history.push(AUTH_ROUTE);
      }
    }
  }, [isInitialUserFetched, user, history]);

  return user;
}
