const style = getComputedStyle(document.body);

// COLORS
export const BORDER_COLOR = style.getPropertyValue("--borderColor");

// primary background color
export const PRIMARY_BACKGROUND_COLOR = style.getPropertyValue(
  "--primaryBackgroundColor"
);
export const PRIMARY_BACKGROUND_COLOR_ACTIVE = style.getPropertyValue(
  "--primaryBackgroundColorActive"
);

export const BOX_SHADOW = style.getPropertyValue("--boxShadow");
export const FONT_COLOR = style.getPropertyValue("--fontColor");
export const PRIMARY_COLOR = style.getPropertyValue("--primaryColor");

// VARIABLES
export const PADDING = style.getPropertyValue("--padding");
export const APP_BORDER_RADIUS = style.getPropertyValue("--appBorderRadius");
