import React, { useEffect, useRef, useState } from "react";
import firebase, { auth } from "../firebase";

import styles from "./AuthPage.module.css";
import { ReactComponent as Logo } from "../common/_assets/svg/logoNoText.svg";
import { ReactComponent as BackgroundImage } from "../common/_assets/svg/authBackground.svg";
import { ReactComponent as GoogleIcon } from "../common/_assets/svg/googleIcon.svg";

import { PADDING } from "../common/variables";
import Toast from "../features/toast/Toast";

function AuthPage() {
  const [toastRef, generateToastMessage] = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitFunction = useAuthSubmitFunction(
    email,
    password,
    isLoading,
    setIsLoading,
    generateToastMessage
  );

  // key binding
  useEffect(() => {
    function handleKeyPress(e) {
      if (e.key === "Enter") {
        submitFunction();
      }
    }

    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [submitFunction]);

  return (
    <div className="pageContainer" style={{ display: "flex" }}>
      {/* background */}
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
        }}
      >
        <BackgroundImage />
      </div>

      {/* toast */}
      <Toast ref={toastRef} />

      <div className={styles.contentContainer}>
        <div className={styles.titleAreaContainer}>
          <div className={styles.logoContainer}>
            <Logo />
          </div>

          <div className={styles.titleContainer}>
            Login to Your <span style={{ fontWeight: 700 }}>Maestra</span>{" "}
            Account
          </div>
        </div>

        {/* email input */}
        <div style={{ marginTop: 10 }}>
          <AuthInput
            type="email"
            label="Email Address"
            value={email}
            onChange={setEmail}
          />
        </div>

        {/* password input */}
        <div style={{ marginTop: 6 }}>
          <AuthInput
            type="password"
            label="Password (at least 6 characters)"
            value={password}
            onChange={setPassword}
          />
        </div>

        <div style={{ marginTop: PADDING }}>
          <AuthButton
            onClick={submitFunction}
            text="Login"
            disabled={isLoading}
          />
        </div>

        <div style={{ marginTop: 10 }}>
          <div
            className={styles.authButtonWhite}
            onClick={() => signInWithGoogle(generateToastMessage, setIsLoading)}
          >
            <GoogleIcon style={{ height: 22, marginRight: 10 }} />
            Login With Google
          </div>
        </div>
      </div>
    </div>
  );
}

function signInWithGoogle(generateToastMessage, setIsLoading) {
  const provider = new firebase.auth.GoogleAuthProvider();
  auth
    .signInWithPopup(provider)
    .then(() => {
      setIsLoading(false);
    })
    .catch((error) => {
      const errorMessage = convertFirebaseAuthErrorToMessage(error);
      generateToastMessage(errorMessage);
      setIsLoading(false);
    });
}

function AuthInput(props) {
  function handleChange(e) {
    props.onChange(e.target.value);
  }

  function getInputIcon() {
    if (props.type === "email") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-at-sign"
        >
          <circle cx="12" cy="12" r="4"></circle>
          <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
        </svg>
      );
    } else if (props.type === "password") {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-key"
        >
          <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
        </svg>
      );
    }

    return null;
  }

  return (
    <div className={styles.authInputContainer}>
      <label className={styles.authInputLabel}>{props.label}</label>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          alignItems: "center",
        }}
      >
        <div className={styles.authInputLeftSide}>
          {getInputIcon(props.type)}
        </div>
        <input
          className={styles.authInput}
          autoFocus={props.autoFocus}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

function AuthButton(props) {
  function onClick() {
    if (!props.disabled) {
      props.onClick();
    }
  }

  return (
    <div
      className="appButton"
      onClick={onClick}
      style={{
        cursor: props.disabled ? "initial" : "pointer",
        width: "100%",
      }}
    >
      {props.text}
    </div>
  );
}

function useAuthSubmitFunction(
  email,
  password,
  isLoading,
  setIsLoading,
  generateToastMessage
) {
  function handleSubmit() {
    setIsLoading(true);
    if (!isLoading) {
      auth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          const errorMessage = convertFirebaseAuthErrorToMessage(error);
          generateToastMessage(errorMessage);
          setIsLoading(false);
        });
    } else {
      console.log("Still loading.");
    }
  }

  return handleSubmit;
}

function convertFirebaseAuthErrorToMessage(error) {
  let errorMessage = "";

  let errorCode = error?.code;
  if (errorCode) {
    if (errorCode === "auth/user-disabled") {
      errorMessage =
        "This account has been disabled. Contact support for more information.";
    } else if (errorCode === "auth/invalid-email") {
      errorMessage = "Email is badly formatted.";
    } else if (
      errorCode === "auth/user-not-found" ||
      errorCode === "auth/wrong-password"
    ) {
      errorMessage = "Incorrect email or password.";
    } else if (errorCode === "auth/network-request-failed") {
      errorMessage = "Check your network connectivity.";
    } else if (errorCode === "auth/too-many-requests") {
      errorMessage = "Please try again in few minutes.";
    } else {
      errorMessage =
        "Servers are having problems with authentication. Please contact support.";
    }
  }

  return errorMessage;
}

function useToast() {
  const toastRef = useRef(null);
  const generateToastMessage = toastRef?.current?.notify;

  return [toastRef, generateToastMessage];
}

export default AuthPage;
