import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";

const config = {
  apiKey: "AIzaSyCM3w7SKmg25eRBgh4tmjPpXDWED-iFp9Q",
  authDomain: "maestrasuite.com",
  databaseURL: "https://maestro-218920.firebaseio.com",
  projectId: "maestro-218920",
  storageBucket: "maestro-218920.appspot.com",
  messagingSenderId: "652465950700",
};

firebase.initializeApp(config);

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage();
export default firebase;
