import React from "react";
import styles from "../../pages/ScormPage.module.css";
import { List, AutoSizer } from "react-virtualized";
import moment from "moment";

import { PRIMARY_BACKGROUND_COLOR_ACTIVE } from "../variables";

function ScormFilesList(props) {
  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
    parent,
  }) {
    function handleClick() {
      props.setSelectedFileId(props.filesArray[index].folderId);
    }

    return (
      <div
        key={key}
        className={styles.listItem}
        parent={parent}
        style={{
          ...style,
          backgroundColor:
            props.filesArray[index].folderId === props.selectedFileId
              ? PRIMARY_BACKGROUND_COLOR_ACTIVE
              : undefined,
        }}
        onClick={handleClick}
      >
        <div className={styles.listItemColumn} style={{ flex: 1 }}>
          {props.filesArray[index].folderName}
        </div>
        <div className={styles.listItemColumn}>
          {getStatusString(props.filesArray[index])}
        </div>
        <div className={styles.listItemColumn}>
          <TimeString value={props.filesArray[index].created} />
        </div>
      </div>
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          rowCount={props.filesArray.length}
          rowRenderer={rowRenderer}
          rowHeight={props.rowHeight}
          height={height}
          width={width}
          overscanRowCount={20}
          filesArray={props.filesArray}
        />
      )}
    </AutoSizer>
  );
}

function TimeString(props) {
  if (!props.value) {
    return "";
  }
  const today = moment(new Date());
  const timeSince = moment.duration(today.diff(props.value));
  var days = timeSince.asDays();
  if (days <= 5) {
    let text = moment(props.value).fromNow();
    return text === "in a few seconds" ? "a few seconds ago" : text;
  } else {
    return moment(props.value).format("MMM D, YY");
  }
}

function getStatusString(scormFolderObj) {
  const totalFileCount = scormFolderObj?.totalFileCount || 0;
  const doneFileCount = scormFolderObj?.doneFileCount || 0;

  if (totalFileCount > 0) {
    if (totalFileCount === doneFileCount) {
      return "Done";
    } else {
      return `Transcribing ${doneFileCount}/${totalFileCount}`;
    }
  } else {
    // probably failed
    return "";
  }
}

export default ScormFilesList;
